.no_underline_link {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Optional: Use the parent's text color instead of default blue */
}

/* Back to Top Button Styling */
#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* 20px from the bottom */
  right: 30px; /* 30px from the right */
  z-index: 99; /* Ensures the button is on top */
  border: none; /* No border */
  outline: none; /* No outline */
  background-color: #0a290a; /* Deep green background color */
  color: white; /* White text color */
  cursor: pointer; /* Pointer/hand icon */
  padding: 10px 20px; /* Padding inside the button */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

#myBtn:hover {
  background-color: #004d00; /* A slightly darker green color on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
  box-shadow: 0 5px 10px rgba(0,0,0,0.2); /* Add shadow for depth */
}

/* Additional style for better visibility on scroll */
#myBtn:active {
  background-color: #003300; /* Even darker green when active/clicked */
  transform: scale(0.95); /* Slightly decrease size when clicked */
}



/* General Body Styling */
.overall {
  padding-top: 30px;
  font-family: cursive;
  background-color: #e8f0ee; /* Updated to a soft green background */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* Header Enhancements */
.site_header h1 {
  color: #004d40; /* Deep green */
  /* Matching romantic pink */
  font-size: 2.5rem;
  padding: 20px 0;
}


/* Footer Enhancements */
.site_footer {
  background-color: #a5d6a7; /* Light green */
  color: #004d40; /* Deep green */
  padding: 0.5rem 0;
  font-size: 0.9rem;
  border-top: 2px solid #2e7d32; /* Deep green */
  margin-top: 50px;
}

.site_footer a {
  color: #004d40;
  text-decoration: none;
  /* Removes underline from links */
}

.site_footer a:hover {
  text-decoration: underline;
  /* Adds underline on hover */
}

.fa-instagram {
  color: #004d40; /* Instagram icon color to match theme */
  /* Instagram icon color to match theme */
}

/* Adjustments for small devices */
@media (max-width: 768px) {

  .site_header,
  .site_footer {
    text-align: center;
  }
}







.things {
  /* Romantic pink */
  color: #004d40; /* Deep green */ 
  margin-bottom: 15px;
}

.section h3, .things {
  margin-top: 20px;
}

.section ul {
  list-style-type: circle;
}

.section ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
  /* Darker text for readability */
}

/* Love Languages Chart Enhancements */
#nicsLoveLanguages,
#amandasLoveLanguages {
  max-width: 300px;
  margin: 20px auto;
}

/* Enhancements for "Things I Love About Amanda" Section */
h2 {
  text-align: center;
}

#diagram {
  width: 100%;
}

#personality {
  width: 100%;
  margin-bottom: 30px;
}

#list-of-things {
  list-style-type: none;
  font-size: larger;
  text-align: center;
}

#about-section {
  margin-bottom: 80px;
}


.albums_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
  gap: 20px; /* Space between grid items */
}
.album_image {
  width: 100%; /* Ensures the image fills the container width */
  display: block; /* Removes any default margin/padding around the image */
  border-radius: 8px; /* Optional: rounded corners for each album item */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
#song-title {
  text-align: center; /* Centers the caption text */
  margin-top: 10px;
}
.text-center {
  text-align: center; /* Centers the caption text */
}
@media (max-width: 768px) {
  .albums_grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets and below */
  }
}

@media (max-width: 480px) {
  .albums_grid {
      grid-template-columns: 1fr; /* 1 column for very small screens */
  }
}
#albums img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances
   shadow on hover for a 'lifted' effect */
}

.vibe{
  background-color: #0a290a;
  border-radius: 20px;
}

.container .grid {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a grid with 3 columns */
  grid-gap: 0; /* No gaps between the grid items */
}

.vibe_img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the area */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, border-radius 0.3s ease;
}
.grid div {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Maintains a 1:1 aspect ratio */
}
.grid div img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}
.container .grid img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances
   shadow on hover for a 'lifted' effect */
   z-index: 1; /* Brings the hovered image to the front */
   border-radius: 10px;
}




.container{
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) { 
  .container {
    max-width: 540px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container {
    max-width: 720px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container {
    max-width: 960px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .container {
    max-width: 1140px;
  }
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
  .container {
    max-width: 1320px;
  }
}











/* Header Styles */
.site_header {
  background-color: #add7af; /* Updated to a soft green background */
  color: #004d40; /* Deep green text color */
  padding: 2rem 0;
  border: 2px solid #2e7d32; /* Deep green border */
  /* Lighter pink border */
  margin-bottom: 30px;
  border-radius: 10px;
}

.site_header h1 {
  font-family: 'Lobster', cursive;
  /* Stylish font for the header */
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}






.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.tab.active {
  background-color: #007bff;
  color: white;
}

.tab-content {
  display: none;
  transition: all 0.5s ease-in-out;
}

.tab-content.active {
  display: block;
}


.scrollUpBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #db7093;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;

  position: fixed;
  bottom: -100px;
  /* Start offscreen */
  right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.7;
  z-index: 1000;
  transition: bottom 0.5s, opacity 0.5s;
  /* Smooth transition for sliding and fading */
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.scrollUpBtn:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: #db7093;
  align-items: center;
}

.scrollUpBtn:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.scrollUpBtn::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.scrollUpBtn:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}

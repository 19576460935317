.question{
  padding: 10px;
}

/* styles.css */

.wheel-holder{
  justify-content: center;
  display: flex;
}

.colour-wheel{
  text-align: center;
}

.color-wheel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200PX;
}

svg {
  cursor: pointer;
  transition: transform 0.5s ease-out;
}

.arrow {
  position: absolute;
  top: 10px;
  font-size: 1.5rem;
  color: black;
}



/* styles.css */

#range-input{
  padding: 0px;
}

.range-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 10px;
}

input[type="range"] {
  width: 100%;
  max-width: 400px;
}

.scale {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: 5px 0;
}

.scale-number {
  font-size: 0.9rem;
  color: black;
}

.scale-number.selected {
  font-weight: bold;
  color: #000;
}

.selected-value {
  margin-top: 10px;
  font-size: 1.2rem;
  color: pink;
}

.go-outer{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.go{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.go .go-title{
  margin-bottom: 20px;
}



/* styles.css */

.date-input-container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.date-title {
  text-align: center;
  margin-bottom: 10px;
}

.date-input{
  text-align: center;
}

input[type="date"] {
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  size: flex;
}





.main-container {
  font-family: cursive;
  background: linear-gradient(-45deg, #db7093, #addfff, #2e7d32);
  animation: gradient 10s ease infinite;
  background-size: 400% 400%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.intro{
  text-align: left;
  color: #D83F87;
  font-size: x-large;
  margin: 0px;
}

.outro{
  text-align: right;
  color: #D83F87;
  font-size: x-large;
  margin: 0px;
}

.title-container {
  text-align: center;
  margin-top: 20px;
  transition: margin-left 0.3s ease;
}

.title-container.shifted {
  margin-left: 300px; /* Adjust as necessary to match the width of the form */
}

.form-container {
    left: 0;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff5f5;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    top: 0;
}


.page-title {
  font-family: cursive;
  font-size: 6rem;
  /* Adjust according to your preference */
  color: #D83F87;
  /* Example color */
  text-align: center;
}

.login-form {
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 100px;
  gap: 1rem;
  animation: slideDownFadeIn 1s ease-out forwards;
  border-radius: 30px;
  /* Vertical alignment */

}


.error-message {
  color: #ff0000;
  /* Red color for the error message */
  margin-bottom: 20px;
  opacity: 0;
  animation: slideDownFadeIn 0.5s ease-out forwards;
  position: absolute;
  margin-right: 50px;
}

@keyframes slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* special_styles.css */
.main-container {
  position: relative;
  overflow: hidden;
  display: flex;
}

.title-container {
  text-align: center;
  transition: margin-left 0.3s ease;
  display: flex;
  align-items: center;
}

.title-container.shifted {
  margin-left: 300px;
  /* Adjust as necessary to match the width of the form */
}

.form-container {
  position: fixed;
  top: 0;
  left: -25%;
  /* Adjust to match the width of the form */
  width: 25%;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 1000;
}

.form-container.visible {
  left: 0;
}

.tab {
  position: absolute;
  top: 50%;
  right: -40px;
  /* Adjust based on the width of the tab */
  transform: translateY(-50%);
  background-color: #fff5f5;
  padding: 10px 10px;
  cursor: pointer;
  z-index: 1001;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.5);
}

.tab span {
  display: block;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}












.no-underline-link {
  text-decoration: none;
  /* Removes underline */
  color: inherit;
  /* Optional: Use the parent's text color instead of default blue */
}



#root{
  background-color: #f0f0f0;

}

/* General Body Styling */
.overall {
  padding-top: 30px;
  font-family: cursive;
  /* Soft pink background */
}

/* Navbar Enhancements */
.navbar {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  background: linear-gradient(-45deg, #db7093, #addfff, #2e7d32);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.navbar-brand {
  /* Romantic pink */
  font-weight: bold;
}

.navbar-brand img {
  height: 50px;
  /* Adjust based on your logo */
  margin-right: 8px;
}

.nav-link {
  color: black !important;
  /* Ensures override */
  /* Ensures override */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  /* Smooth transition for hover effect */
  margin: 0 10px;
  /* Adds horizontal spacing */
  border-radius: 5px;
  /* Optional: adds rounded corners */
  padding: 5px 10px;
  /* Adjusts padding to make the links look more like buttons */
  border-radius: 10px;
}

.nav-link:hover,
.nav-link:focus {
  color: #ffffff !important;
  /* Changes text color on hover */
  background-color: #db7093;
  /* Changes background color on hover */
}








.navbar2 {
  background-color: #004d40;
  /* Darker shade of green */
  border-bottom: 2px solid #2e7d32;
  /* Deep green border */
}

.navbar2-brand {
  color: #a5d6a7;
  /* Light green */
  font-weight: bold;
}

.nav-link2 {
  color: #004d40 !important;
  /* Deep green */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  /* Smooth transition for hover effect */
  margin: 0 10px;
  /* Adds horizontal spacing */
  border-radius: 5px;
  /* Optional: adds rounded corners */
  padding: 5px 10px;
  /* Adjusts padding to make the links look more like buttons */
}

.nav-link2:hover,
.nav-link2:focus {
  color: #fff !important;
  /* Deep green text color on hover */
  background-color: #004d40;
  /* Light green background color on hover */
}


















/* Header Enhancements */

#celebration-section{
  padding-bottom: 30px;
}


.site-header h1 {
  color: #d43c6f;
  /* Matching romantic pink */
  font-size: 2.5rem;
  padding: 20px 0;
}

/* Countdown Section */
#countdown-section {
  background-color: #ffccd5;
  /* Soft pink */
  color: #d43c6f;
  padding: 20px 0;
  border: 2px solid #f3c1d3;
  border-radius: 10px;
}

#countdown {
  font-size: 1.5rem;
  font-weight: bold;
}



/* Carousel Enhancements */
#photoSlideshow {
  margin-top: 30px;
  margin-bottom: 10px;
}

.slideshow-image-holder {
  height: 800px;
  /* Adjust height as needed */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-image-holder img {
  height: 90%;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.slideshow-image-holder img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances
   shadow on hover for a 'lifted' effect */
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #db7093;
  /* Pink icons */
  border-radius: 10px;
}

/* Footer Enhancements */
.site_footer {
  background-color: #ffe0f0;
  color: #d6336c;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  border-top: 2px solid #f3c1d3;
  margin-top: 50px;
}

.site-footer a {
  color: #d6336c;
  text-decoration: none;
  /* Removes underline from links */
}

.fa-instagram {
  color: #d6336c;
  text-decoration: none;
  /* Instagram icon color to match theme */
}

/* Adjustments for small devices */
@media (max-width: 768px) {

  .site-header,
  .site-footer {
    text-align: center;
  }
}






#eventImage {
  height: 350px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 20%;
}

.timeline-snake {
  position: relative;
}

.timeline-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.timeline-event {
  width: 400px;
  margin: 0 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20%;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

@media only screen and (max-width: 768px) {
  .timeline-row {
    flex-direction: column;
  }

  .timeline-event {
    width: 100%;
    margin: 10px 0;
    /* Adjust for vertical stacking */
  }
}

.timeline-event:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances shadow on hover for a 'lifted' effect */
}






/* Places Page */

/* Map and Controls Styling */
#map {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.toggle-controls {
  /* Soft pink background for toggle controls */
  border-radius: 8px;
  margin-bottom: 20px;
}










/* Specific Styles for About Page */


#about-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

#about-content.show {
    max-height: 2000px; /* Set this to a high enough value to accommodate your content */
}

.row{
  display: flex;
}

#person{
  width: 50%;
}

.name-title{
  display: flex;
  justify-content: center;
  align-items: center; 
}


.bg-blue,
.bg-green {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;

}

.bg-blue {
  background-color: #e0f7fa;
  /* Soft blue for Nic's section */
}

.bg-green {
  background-color: #e8f5e9;
  /* Soft green for Amanda's section */
}

h2 {
  color: #d43c6f;
  /* Romantic pink */
  margin-bottom: 15px;
}

.section h3 {
  color: #d43c6f;
  margin-top: 20px;
}

.section ul {
  list-style-type: circle;
}

.section ul li {
  margin-bottom: 10px;
  font-size: 16px;
  /* Darker text for readability */
}

/* Love Languages Chart Enhancements */
#nicsLoveLanguages,
#amandasLoveLanguages {
  width: 500px;
  margin: 20px 70px;
}

/* Enhancements for "Things I Love About Amanda" Section */
h2 {
  text-align: center;
}

#diagram {
  width: 100%;
}

#personality {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#personality:hover {
  transform: scale(1.01);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances shadow on hover for a 'lifted' effect */
}

#list-of-things {
  list-style-type: none;
  font-size: larger;
  text-align: center;
}

#about-section {
  margin-bottom: 80px;
}


.albums-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Creates 3 columns */
  gap: 20px;
  /* Space between grid items */
}

.album-image {
  width: 100%;
  /* Ensures the image fills the container width */
  display: block;
  /* Removes any default margin/padding around the image */
  border-radius: 8px;
  /* Optional: rounded corners for each album item */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#song-title {
  text-align: center;
  /* Centers the caption text */
  margin-top: 10px;
}

.text-center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centers the caption text */
}

@media (max-width: 768px) {
  .albums-grid {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for tablets and below */
  }
}

@media (max-width: 480px) {
  .albums-grid {
    grid-template-columns: 1fr;
    /* 1 column for very small screens */
  }
}

#albums img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances
   shadow on hover for a 'lifted' effect */
}

.container .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Creates a grid with 3 columns */
  grid-gap: 0;
  /* No gaps between the grid items */
}

.vibe-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures image covers the area */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid div {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* Maintains a 1:1 aspect ratio */
}

.grid div img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.container .grid img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances
   shadow on hover for a 'lifted' effect */

}








@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-5px);
  }
  50% {
      transform: translateX(5px);
  }
  75% {
      transform: translateX(-5px);
  }
}

.vibe_img:hover {
  animation: bounce 1s;
}

.section-header {
  animation: fadeIn 2s;
}

.list-item {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.list-item.visible {
  opacity: 1;
}

.shake-on-click {
  display: inline-block;
}

.shake-on-click:active {
  animation: shake 0.5s;
}





















/* Love Messages Page Specific Styles */

/* Header Styles */
.site-header {
  background-color: #ffe0f0;
  color: #fff;
  /* Deep pink text color */
  padding: 2rem 0;
  border: 2px solid #f3c1d3;

  /* Lighter pink border */
  margin-bottom: 30px;

  border-radius: 10px;
}

.site-header h1 {
  font-family: 'Lobster', cursive;
  /* Stylish font for the header */
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

/* Messages Section Styles */
#love-messages {
  padding-bottom: 2rem;
  border-radius: 15px;
  /* Rounded corners for the section */
}

#love-messages p {
  font-family: cursive;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

#messageForm {
  padding: 5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffe0f0;
  border: 2px solid #f3c1d3;
  /* Soft rounded corners for the form */
  /* Subtle shadow for depth */
}



/* test */




/* Gallery Section Styles */

.gallery-container {
  margin: auto;
  width: 80%;
  padding: 10px;
  text-align: center;
}

.gallery-item img {
  height: 500px;
  /* Fixed height for all images */
  width: auto;
  /* Maintain the original width of the images */
  object-fit: cover;
  /* Maintain aspect ratio and cover the area */
  border-radius: 10px;
  /* Adds rounded corners to images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for hover effects */
  animation: slideDownFadeIn 1s ease-out;
}

.gallery-item img:hover {
  transform: scale(1.03);
  /* Slightly enlarges images on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhances shadow on hover for a 'lifted' effect */
  cursor: pointer;
}


/* Responsive adjustments for better viewing on smaller devices */
@media (max-width: 768px) {
  .gallery-item {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* Adjusts grid for smaller screens */
  }
}

.image-popup {
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0, 0.9);
  /* Black background with opacity */
}

.image-popup img {
  animation: expandOut 0.25s ease-out;
}

@keyframes expandOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.image-popup .image-close {
  position: absolute;
  top: 25px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.image-popup img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.caption {
  text-align: center;
  padding: 10px 0;
}



.gallery-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.gallery-tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-right: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.gallery-tab:hover {
  background-color: #e1e1e1;
}

.gallery-tab.active {
  background-color: #db7093;
  color: white;
  border: 1px solid #db7093;
}





















/* MAPS */

#map1,
#map2,
#map {
  margin-bottom: 30px;
  border-radius: 20px;

}

.pulsing-icon-about {
  background-color: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: relative;
}

.pulsing-icon-about::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid red;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}




/* MAPS MORE */

.toggle-controls {
  left: 10px;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.custom-checkbox {
  appearance: none;
  background-color: #f0f0f0;
  margin-right: 5px;
  font-size: 1.15em;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  position: relative;
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}



/* Picnic Checkbox - Green when checked */
#picnic.custom-checkbox:checked + {
  background-color: #28a745;
  /* Green */
  border-color: #28a745;
}

/* Dance Checkbox - Red when checked */
#dance.custom-checkbox:checked {
  background-color: #dc3545;
  /* Red */
  border-color: #dc3545;
}

/* Eat Out Checkbox - Blue when checked */
#eatOut.custom-checkbox:checked {
  background-color: #007bff;
  /* Blue */
  border-color: #007bff;
}

#mcdonalds.custom-checkbox:checked {
  background-color: #fffb00;
  /* Blue */
  border-color: #fffb00;
}



.pulsing-icon-picnic,
.pulsing-icon-dance,
.pulsing-icon-eatOut,
.pulsing-icon-mcdonalds {
  border-radius: 50%;
}

.pulsing-icon-picnic::after,
.pulsing-icon-dance::after,
.pulsing-icon-eatOut::after,
.pulsing-icon-mcdonalds::after {
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1s infinite;
}

.pulsing-icon-picnic,
.pulsing-icon-picnic::after {
  background-color: green;
  border: 2px solid green;
}

.pulsing-icon-dance,
.pulsing-icon-dance::after {
  background-color: red;
  border: 2px solid red;
}

.pulsing-icon-eatOut,
.pulsing-icon-eatOut::after {
  background-color: blue;
  border: 2px solid blue;
}

.pulsing-icon-mcdonalds,
.pulsing-icon-mcdonalds::after {
  background-color: orange;
  border: 2px solid orange;
}



.scrollUpBtn.show {
  bottom: 20px;
  /* Slide up into view */
  opacity: 1;
  /* Fully visible */
}

#insta_box{
  display: flex;
  align-items: center;
}







.book {
  border-radius: 10px;
  width: 60%;
  background-color: #ffb6c3;
  box-shadow: 1px 1px 5px #000;
  transform: preserve-3d;
  perspective: 2000px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  position: relative;
}

.cover {
  color: #d43c6f;
  top: 0;
  position: absolute;
  background-color: #ffccd5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  transform-origin: 0;
  box-shadow: 1px 1px 5px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover-text {
  text-align: center;
}

.book.open .cover {
  transition: all 1s;
  transform: rotateY(-78deg);
}

p {
  font-size: 20px;
}

.book-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-text {
  padding: 10%;
  box-sizing: border-box;
}




















.loader {
  width: 7em;
  height: 4em;
  position: relative;
  animation: beat 1s infinite;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50px;
  height: 80px;
  border-radius: 5em 5em 0 0;
  animation: coldblue 1s infinite;
}

.loader::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.loader::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes coldblue {
  0%, 100% {
    background-color: rgb(255, 255, 255);
  }

  50% {
    background-color: rgb(198, 23, 23);
  }
}







/* src/components/styles.css */

.acknowledgment {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.acknowledgment a {
  color: #d6336c; /* or any other color that fits your design */
  text-decoration: none;
}

.acknowledgment a:hover {
  text-decoration: underline;
}
